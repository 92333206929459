import SousMenu from './SousMenu';
import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import Ad from '../commun/adSense';
import { Link } from 'react-router-dom';
import border from '../../images/border.png'
import fleur from '../../images/fleur.png'
import presentation from '../../images/presentation.webp'
import { Helmet } from 'react-helmet';
import Tableau from '../commun/Tableau';
import GraphiqueCM from '../commun/Graph';



export default class Presentation extends Component
{

  constructor()
  {
    super();
    this.state = {
      afficherGraphiqueCM : false
    }
  }

  graphiqueCM = () => {

      this.setState({ afficherGraphiqueCM: true })

}
 
    render()
    {
        return(
        <div>
           <Helmet>
           <title>Jeux et exercices d'orthographe gratuits en ligne</title>
          <meta name="description" content="Le meilleur site français pour évaluer et tester son niveau d'orthographe, de grammaire ou de conjugaison, les exercices sont classés par niveaux (du simple au difficile), ils sont accessibles gratuitement en ligne pour les enfants ou les adultes." />
        </Helmet>
          <h1 className="centre couleur grandTitre">S'amuser avec des exercices d'orthographe !</h1>
          <div className='centre espaceTitreBas'>evalquiz : le site numéro 1 du divertissement intelligent</div>
        
          <Row><p>Améliorez votre orthographe grâce à des dizaines de tests et d'exercices de différents styles (quiz, dictée, jeux...). Tous sont gratuits et ne nécessitent aucune inscription.
          La difficulté des tests est indiquée la plupart du temps, tout le monde peut jouer de 9 à 99 ans, les adultes comme les enfants. Amusez-vous bien !</p></Row>
        <div className="centre"><img src={fleur} alt="fleur"  width="300" height="255" ></img></div>

          <h2 id="dictee">La grande dictée</h2>
          <p>Ce test est une dictée ludique pour évaluer son orthographe. Le texte de cette dictée est donné régulièrement aux élèves de CM2 par l'Éducation nationale pour évaluer le niveau d'orthographe des jeunes au cours du temps. Vous pourrez donc comparer vos résultats avec ceux des élèves de CM2. </p>
          <p>En trente-quatre ans, le nombre d'erreurs a augmenté de manière régulière, pour passer de 10,7 fautes en moyenne en 1987 à 19,4 en 2021.</p>
    {this.state.afficherGraphiqueCM ? <React.Fragment><h2>Les résultats officiels des élèves de CM2 au cours des années</h2><GraphiqueCM></GraphiqueCM></React.Fragment> : <div className='espaceHautBas centre fontMoyenne souris classementCon'  onClick={this.graphiqueCM}>➕ Résultats officiels des élèves de CM2</div>}
<h3>Peux-tu faire mieux que les élèves ?</h3>
    
          <div className='centre fontMoyenne  boutonDecor'><Link to="/orthographe/0">La phrase pour s'entrainer</Link></div>
<div className='centre fontMoyenne  boutonDecor'><Link to="/orthographe/1">Commencer le test d'orthographe de référence</Link></div>

<div className="centre espaceHautBas"><img src={border} alt="bordure"  width="100" height="51"></img></div>
          <h2>Autres jeux et tests sur l'orthographe et la grammaire</h2>
          <Row gutter={8} className="espaceHaut">
          <Col md={8}>
            <h1>Les concours</h1>
            <p>Les concours sont nos jeux les plus difficiles, ils exigent beaucoup d'attention pour réaliser de bons scores. </p>
          <p>Chaque concours a un système de classement qui vous permet de vous évaluer par rapport aux autres.</p>
             
          </Col>
        <Col md={10}>
        <Card title="Les concours pour apprendre et réviser">
          <p><a href="https://concours.evalquiz.com/presevaluation">Les évaluations de l'Éducation nationale</a></p>
          <p><a href="https://concours.evalquiz.com/presconjugaison">Le 2 concours sur la conjugaison</a></p>
          <p><a href="https://concours.evalquiz.com/presorthographe">Le test de l'orthographe en 64 étapes</a></p>
          <p><a href="https://concours.evalquiz.com/presvocabulaire">Les 3 concours sur le vocabulaire français</a></p>
          </Card>
          <Card className="espaceHaut" title="Les concours pour s'amuser">
          <p><a href="https://concours.evalquiz.com/mots-presentation">La farandole des mots</a></p>
          <p><a href="https://concours.evalquiz.com/mots-scrable">Le Scrabble solitaire</a></p>
          <p><a href="https://concours.evalquiz.com/presmot">Le challenge des jeux de lettres</a></p>
          <p><a href="https://cerebral.evalquiz.com/chiffres-lettres">Le concours du mot le plus long</a></p>
          </Card>
        </Col>
        
        </Row>
        <div id="defi" className="centre"><img src={border} alt="bordure"  width="100" height="51" ></img></div>
          <Row gutter={8} className="espaceHaut">
          <Col md={8}>
            <h1>Les défis</h1>
            <p>Un défi est un ensemble de petits jeux (quiz, dictée, phrase à trou, conjugaison...), chaque jeu a un objectif qu'il faut impérativement atteindre pour passer au jeu suivant. Si vous réussissez tous les jeux du défi, vous gagnez le défi. </p>
            <p>Seul les meilleur(e)s peuvent réussir un défi à la première tentative, ceux qui réussissent cet exploit verront leurs noms inscrits sur notre tableau d'honneur.</p>
           <p>Pour vous préparer aux défis, n'hésitez pas à vous entrainer avec tous les exercices et quiz présents sur notre site.</p>
             
          </Col>
        <Col md={10}>
          <SousMenu type='defigrammaire' titre="Les défis de l'orthographe, de la grammaire et de la conjugaison"></SousMenu>
          <div className='espaceHaut'></div>
          <SousMenu type='defijeux' titre="Les défis des jeux de lettres et de mots"></SousMenu>
        </Col>
        </Row>
     

        <div className="centre"><img src={border} alt="bordure"  width="100" height="51"></img></div>
<div className="couleur titre centre">Les tests</div>
    <Row className="margeHaut">
    <Col xs={24} sm={12}><Link to="/dictees"><div className="tuile">Les dictées</div></Link></Col>
    <Col xs={24} sm={12}><Link to="/grammaire-orthographe"><div className="tuile">Grammaire / Orthographe</div></Link></Col>
    <Col xs={24} sm={12}><Link to="/conjugaison"><div className="tuile">Conjugaison</div></Link></Col>
    <Col xs={24} sm={12}><Link to="/jeux-mots"><div className="tuile">Jouer avec les mots</div></Link></Col>
    </Row>

    <div className="centre"><img className="resp" src={presentation} alt="presentation" width="1000" ></img></div>
    <Ad></Ad>
    <Row>
      <Col xs={24}>
      <div className='couleur titre centre'>Les défis</div>
      <p className='centre'>1500 personnes ont déjà réussi à inscrire leurs noms au tableau d'honneur. Les 5 meilleurs de tous les temps : Gwendal, Valérie, Marie, Yasmina et Sylvie.</p>
      </Col>   </Row>
        <Tableau type="defiorthographe"></Tableau>
     
      
      <div className="centre"><img src={border} alt="bordure"  width="100" height="51"></img></div>

     
        <Row className="espaceHaut">
           <p>Quelquefois plusieurs orthographes sont possibles pour un mot, le programme ne prend en compte qu'une seule orthographe. Le problème se pose particulièrement avec les accents circonflexes sur les u et i.
          Nous essayons sur ce site de ne prendre en compte que la nouvelle orthographe sans accent circonflexe (plus simple sur un clavier).</p>
          <p>L'orthographe n'est pas une science exacte et l'homme n'est pas infaillible, vous pouvez nous contacter directement si vous avez des remarques ou des propositions en consultant la page des <a href="https://evalquiz.com/home/faq">mentions légales.</a></p>
          </Row>
          <h2>Crédits</h2>
          <p>Les icônes de bordure sont l'oeuvre d'<a href="https://thenounproject.com/zzyzz/">Olena Panasovska.</a></p>
          <p>L'icône des lauriers est l'oeuvre d'<a href="https://thenounproject.com/AliceNoir/">Alice Noir.</a></p>
         <p>Photo de présentation d'Alexandr Podvalny provenant de Pexels</p>
     
          <Row className="espaceHaut"></Row>
          <Ad></Ad>
        </div>)
    }

}
