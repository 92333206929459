import DonneeDictee from './DonneeDictee';
import DonneesListeMots from './DonneeListeMots';
import DonneeMemoire from './DonneeMemoire';
import DonneesMots from './DonneesMots';
import DonneeOrthographeMot from './DonneeOrthographeMot';
import DonneePhraseErreur from './DonneePhraseErreur';
import DonneePhraseTrou from './DonneePhraseTrou';
import DonneesJeuxConcours from '../data/donneesJeuxConcours';
import DonneesConcours from '../data/donneesConcours';
import DonneesConcoursJeux from '../data/DonneesConcoursJeux';
import DonneesConcoursOrthographe from '../data/DonneesConcoursOrthographe';
import DonneeMasterMot from '../data/DonneMasterMot';
import DonneeVerbeConjugaison from './DonneeVerbeConjugaison';
import DonneeDesordre from './DonneeDesordre';
import DonneeQcmMelange from './DonneeQcmMelange';
import DonneeBoogle from './DonneeBoogle';
import DonneeQcm from './DonneeQcm';

function donneesSuivantType(type) {
    let donnees = null;
    switch (type) {
        case 'dictee':
            donnees = DonneeDictee;
            break;
        case 'listemots':
            donnees = DonneesListeMots;
            break;
        case 'memoire':
            donnees = DonneeMemoire;
            break;
        case 'motdefinition':
            donnees = DonneesMots;
            break;
        case 'orthographemot':
            donnees = DonneeOrthographeMot;
            break;
        case 'phraseerreur':
            donnees = DonneePhraseErreur;
            break;
        case 'phrasetrou':
            donnees = DonneePhraseTrou;
            break;
        case 'verbeconjugaison':
            donnees = DonneeVerbeConjugaison;
            break;
        case 'concours':
            donnees = DonneesJeuxConcours;
            break;
        case 'defijeux':
            donnees = DonneesConcoursJeux;
            break;
        case 'defigrammaire':
            donnees = DonneesConcoursOrthographe;
            break;
        case 'defiorthographe':
            donnees = DonneesConcours;
            break;
        case 'mastermind-mot':
            donnees = DonneeMasterMot;
            break;
        case 'desordre':
            donnees = DonneeDesordre;
            break;
        case 'qcmmelange':
            donnees = DonneeQcmMelange;
            break;
        case 'boogle':
            donnees = DonneeBoogle;
            break;
        case 'qcm':
            donnees = DonneeQcm;
            break;

        default:
            break;

    }
    return donnees;
}



export default function obtenirDonneesJeu(idTest, type) {
    let concours = false;
    if (idTest > 10000) {
        type = 'concours';
        concours = true;
    }
    let donnees = donneesSuivantType(type);

    let jeux = donnees.find(info => info.id === idTest);
    console.log(jeux)
    return { concours, ...jeux };
}

export { donneesSuivantType }